import {Component, OnDestroy, OnInit,Inject} from '@angular/core';
import {map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import { Router} from '@angular/router';
import {DataSharingService} from '../../../service/data-sharing.service';
import {SiteService} from '../../../../global/services/site/site.service';
import {RequestService} from '../../../../global/services/request/request.service';
import {apiEndPoints, config, keywords,environment} from '../../../../../environments/root/environment';
import {SetTitleService} from '../../../service/set-title.service';
import {AllOfficeAdapt, AllOfficeData} from '../../../model/response/all-offices';
import {Subscription } from 'rxjs';
import {PlatformLocation,DOCUMENT} from '@angular/common';
@Component({
  selector: 'app-jp-all-offices',
  templateUrl: './jp-all-offices.component.html',
  styleUrls: ['./jp-all-offices.component.scss'],
  providers:[AllOfficeAdapt]
})

export class JpAllOfficesComponent implements OnInit ,OnDestroy{
  public allOfficeList : AllOfficeData[]=[];
  public viewGoogleLink:string="";
  public mailtoText:string="";
  public telText:string="";
  public dataFound: boolean;
  public officesOperatingHours: string="";
  SubscriptionList:Subscription[]=[];
  siteBaseURL:any;
  currentDate:any;
  datelocale:any;
  
  constructor(private dataSharingService: DataSharingService, private _translate:TranslateService, private siteService:SiteService,
              private router:Router, private allOfficeAdapt:AllOfficeAdapt,
              private requestService:RequestService,private titleService:SetTitleService,              
             private platformLocation: PlatformLocation,@Inject(DOCUMENT) private document: Document)
  {
    this._translate.setDefaultLang(this.siteService.getSiteLocale());    
    this.siteBaseURL = environment[config.theme + siteService.getSiteLocale()]['siteBaseURL'];
    const translateGetViewGoogleLinkSubscription = this._translate.get('view_google_link').subscribe(val => this.viewGoogleLink = val);
    const translateGetOfficesOperatingHoursSubscription = this._translate.get('offices_operating_hours').subscribe(val => this.officesOperatingHours = val);
    const translateGetTranslationSubscription = this._translate.getTranslation(siteService.getSiteLocale()).subscribe((res)=>{
      this.titleService.setTitleForPage(res['office_locator_page_title']);
    });
    this.mailtoText=keywords.mailto;
    this.telText=keywords.tel;
    this.getAllOfficesList();
    this.dataSharingService.getWidgetListName(keywords.allOfficesWidget);
    this.SubscriptionList.push(translateGetViewGoogleLinkSubscription);
    this.SubscriptionList.push(translateGetOfficesOperatingHoursSubscription);
    this.SubscriptionList.push(translateGetTranslationSubscription);
    this.currentDate = 'Mon july 09 2024 18:53:46';//new Date();
    this.setDetailsPageMetaTags(document);
  }

  ngOnInit() {
    localStorage.removeItem("requstedRedirectURL");
    
  }
  getAllOfficesList(){
    const requestServiceGetRequestSubscription = this.requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.getAllOffice,null,null).pipe(
      map((res: AllOfficeData) => this.allOfficeAdapt.adapt(res)))
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data) {
          this.allOfficeList=res.data;
          this.dataFound=true;
        }else{
          this.dataFound=true;
        }
      }, (error) => {
            this.dataFound=true;
      });
      this.SubscriptionList.push(requestServiceGetRequestSubscription);

  }
  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  }
  /**
   * Canonical changes 
   */

  setDetailsPageMetaTags(document: Document) {
    const siteBaseURL =  this.siteBaseURL;
    let metaURL = siteBaseURL + this.platformLocation.pathname;
    let orgNameList = ['/it/','/cognizant/','/yourrailindustryjob/', '/geniuswithin/'];
    orgNameList.forEach(orgName => {
      if (this.platformLocation.pathname.indexOf(orgName) > -1) {
        metaURL = siteBaseURL + this.platformLocation.pathname.replace(orgName, '/');
      }
    });
    this.updateCanonicalUrl(metaURL,document);
  }

  updateCanonicalUrl(url: string, document: Document) {
    const head = document.getElementsByTagName('head')[0];
    var element: HTMLLinkElement = document.querySelector(`link[rel='canonical']`) || null
    if (element == null) {
      element = document.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url)
  }
}
