import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Pipe({ name: 'dateFormatPipe' })
export class DateFormatPipe implements PipeTransform {
  // adding a default format in case you don't want to pass the format
  // then 'yyyy-MM-dd' will be used
//   transform(date: Date | string, day: number, format: string = 'yyyy-MM-dd'): string {
//     date = new Date(date);  // if orginal type was a string    
//     moment.locale('nl');
//     moment(date).format('DD MMMM YYYY');    
//     console.log('Date : ' + moment(date).format('DD MMMM YYYY'));
//     return moment(date).format('DD MMMM YYYY');
//   }
transform(date: Date | string, format: string,locale: string): string {
    date = new Date(date);  // if orginal type was a string    
    moment.locale(locale);
    //moment(date).format('DD MMMM YYYY');    
    //console.log('Date : ' + moment(date).format('DD MMMM YYYY'));
    return moment(date).format(format);
  }
};
