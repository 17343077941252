import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {GoogleJobResponse} from '../../../../../model/response/google-job-response';
import {ActivatedRoute} from '@angular/router';
import {config, keywords} from '../../../../../../../environments/root/environment';
import {DataSharingService} from '../../../../../service/data-sharing.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SiteService } from 'src/app/global/services/site/site.service';

@Component({
  selector: 'app-jp-apply-confirmation',
  templateUrl: './jp-apply-confirmation.component.html',
  styleUrls: ['./jp-apply-confirmation.component.scss']
})
export class JpApplyConfirmationComponent implements OnInit, OnDestroy {
 @Input() public googleJobResponse:GoogleJobResponse[];
  public showErrorMesasges: boolean;
  public jobTitle: string;
  public jobRef: string;
  isOrgName: boolean;
  orgName: string
  isExpertOrg: boolean;
  activatedRouteQueryParamMapSubscription:Subscription;
  public isHungaryCountry: boolean = false;
  constructor(private activatedRoute:ActivatedRoute, private dataSharingService : DataSharingService, private router : Router,
    private siteService: SiteService) { 
    this.isHungaryCountry = this.siteService.getDomain()== 'HU' ? true : false;
  }

  ngOnInit() {
    // this.isOrgName = (this.router.url.indexOf(config.HaysCareer) == 1 || this.router.url.indexOf(config.HaysHTS) == 1) ? true : false;
    config.ExpertClientsName.forEach(a => { if (this.router.url.includes(a)) this.isOrgName = true; })
    this.dataSharingService.getWidgetListName(keywords.jobAppliedWidget);
    this.activatedRouteQueryParamMapSubscription = this.activatedRoute.queryParamMap.subscribe(param=>{
      if(param.get('jobApply'))
        this.showErrorMesasges=true;
      if(param.get('jobTitle'))
        this.jobTitle= param.get('jobTitle');
      if(param.get('jobRef'))
        this.jobRef= param.get('jobRef');
      if(param.get('orgName')){
        this.orgName= param.get('orgName');
        config.ExpertClientsName.forEach(expertOrg => {
          if (this.router.url.includes(expertOrg))
            this.isOrgName = true;
          if(this.orgName == expertOrg)
            this.isExpertOrg = true;
          })
      }
    });
   /* this.searchService.sharedJobDetailData.subscribe(jobDetailData=> {
      this.googleJobResponse = jobDetailData;
    });*/
  }
  ngOnDestroy(): void {
    if(this.activatedRouteQueryParamMapSubscription){
    this.activatedRouteQueryParamMapSubscription.unsubscribe();}
  }
}
