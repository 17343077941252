<div class="form-group" [formGroup]="parent">
  <label  [translate]="router.url.indexOf(updateYourDetailsUrl) > -1 ? 'update_your_details_upload_cv_lable':'upload_cv_heading'" *ngIf="router.url.indexOf('apply')===-1"></label>
  <div class="help-text" [translate]="isGo1Page ? 'goOne_only_onedocument_text' : 'only_onedocument_text'"></div>
  <div class="help-text" [translate]="router.url.indexOf(updateYourDetailsUrl) > -1 ? 'update_your_details_upload_file_text':'upload_file_text'"></div>
  <div class="help-text" [translate]="'maximum_file_size_text'"></div>
  <input id="uploadInput" [hidden]="true" class="local-drive" type="file"
    (change)="uploadFile($event.target.files)">
  <span *ngIf="(localDrive || dropBoxData) && !isCVSelected && !uploadedCvCheck && router.url.indexOf('apply')===-1" [translate]="'selected_file'"> </span>
  <span *ngIf="!isCVSelected && router.url.indexOf('apply')===-1 && !uploadedCvCheck" [textContent]="dropBoxData?cvResponse.fileName:fileObject?fileObject.name:''"></span>
  <ul class="upload-cv" >
    <ng-container *ngIf="status==='true'">
      <li id="gtm_upload_dropbox_cv" (click)="connectPartnerForCV('dropbox')"
      [ngClass]="fileUploadMedium.isActiveDropbox? 'active' : ''">
        <a class="dropbox">
          <img src="../../../assets/images/icon_dropbox.png" alt="dropbox" />
          <p [translate]="'dropbox_text'"></p>
        </a>
      </li>
      <!--<li  id="gtm_upload_drive_cv" (click)="connectPartnerForCV('gdrive')"
      [ngClass]="fileUploadMedium.isActiveGDrive ? 'active' : ''" >
        <a class="google-drive">
          <img src="../../assets/images/icon_googledrive.png" alt="Google Drive" />
          <p [translate]="'gdrive_text'"></p>
        </a>
      </li>-->
    </ng-container>
    <li id="gtm_upload_local_cv"(click)="selectedFile()"
    [ngClass]="fileUploadMedium.isActiveLocalDrive ? 'active' : ''">
      <a class="local-drive " >
        <img src="../../assets/images/icon_hdd.png" alt="Local Drive" />
        <p [translate]="'hard_disk_text'"></p>
      </a>

    </li>
  </ul>
  <span class="error-msg" *ngIf="!validationFormatFlag || !validationSizeFlag" [translate]="'invalid_cv_msg'">
  </span>
  <div *ngIf="router.url.indexOf('apply')>-1">
  <div class="selected-resume" *ngIf="(localDrive || dropBoxData) && !isCVSelected">
    <span class="icon-doc" [textContent]="dropBoxData?cvResponse.fileName:fileObject?fileObject.name:''"></span>
    <span id="gtm_upload_cv_clear" class="icon-cancel" (click)="clearCv()"></span>
  </div>
  </div>
</div>
